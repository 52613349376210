
	import PageBase from '@/Page/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageHome extends PageBase {
		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickNavigate(e: PointerEvent): Promise<void> {
			e.preventDefault();

			// Animate out children
			this.animateOut();

			// Navigate to next page
			this.$router.push({ name: 'PagePrologue' });
		}
	}
